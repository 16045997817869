import React from "react"
import BodyCard from "../../../organisms/body-card"
import CustomerTable from "../../../templates/customer-table"
import CustomersPageTableHeader from "./header"

const Customers = () => {
  return (
    <div className="flex flex-col grow h-full">
      <div className="w-full flex flex-col grow">
        <BodyCard
          customHeader={<CustomersPageTableHeader activeView="customers" />}
        >
          <CustomerTable />
        </BodyCard>
      </div>
    </div>
  )
}

export default Customers
